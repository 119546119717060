import {
  Navigate,
  Route,
  Routes as AdminRoutes,
  useLocation,
} from "react-router-dom";
import {
  useCallback,
  useEffect,
  useState,
  useContext,
  lazy,
  Suspense,
} from "react";
import { UserContext } from "../../context/UserContext";
import { compareSync } from "bcryptjs";
import { adminPermissions } from "../../Permissions";
import Fallback from "./components/FallBack/Fallback";
import { URL } from "../../urls";
import ProtectedRoute from "./components/ProtectedRoute/ProtectedRoute";
import NotFoundPage from "../../components/NotFound/NotFoundPage";
import ManageUsers from "./pages/ManageUsers/ManageUsers";

//pages
// const AssessmentRoutes = lazy(() =>
//   import("./pages/Assessments/AssessmentRoutes")
// );
const QuestionBankRoutes = lazy(() =>
  import("./pages/QuestionBank/QuestionBankRoutes")
);
const SettingsRoutes = lazy(() => import("./pages/Settings/SettingsRoutes"));
const CampaignRoutes = lazy(() =>
  import("./pages/CampaignRework/CampaignRoutes")
);
const Clients = lazy(() => import("./pages/Clients/Clients"));
const NewsLetter = lazy(() => import("./pages/Newsletter/Newsletter"));
const BillingRoutes = lazy(() => import("./pages/Billing/BillingRoutes"));
const ReportRoutes = lazy(() => import("./pages/Report/ReportRoutes"));
const SiteAdminBillings = lazy(() =>
  import("./pages/Billing-Site-Admin/Billings")
);
const CandidatesRoutes = lazy(() =>
  import("./pages/Candidates/CandidatesRoutes")
);
const PipelineRoutes = lazy(() => import("./pages/Pipelines/PipelineRoutes"));
const DashboardRoutes = lazy(() => import("./pages/Dashboard/DashboardRoutes"));
const FeedBackRoutes = lazy(() => import("./pages/Feedback/FeedBackRoutes"));
const ExamRoutes = lazy(() => import("./pages/Exams/ExamRoutes"));
const ExamRoutesNew = lazy(() => import("./pages/ExamsUpdated/ExamRoutes"));
const AffiliateRoutes = lazy(() => import("./pages/Affiliate/pages/Affiliate"));
const AssessmentRoutes = lazy(() =>
  import("./pages/Assessment/AssessmentRoutes")
);
const AnalyticsRoutes = lazy(() => import("./pages/Analytics/AnalyticsRoutes"));

// components
import AdminHeader from "./components/AdminHeader/AdminHeader";
import AdminSidebar from "./components/AdminSidebar/AdminSidebar";

// hooks
import useWindowDimensions from "../../hooks/useWindowSize";
import { ROUTES } from "../../Routes";
import { useNavigate } from "react-router-dom";
import Downloads from "./pages/Downloads/Downloads";

const AdminDashboard = () => {
  const { width } = useWindowDimensions();
  const [isOpen, setIsOpen] = useState(true);
  const [lastFetchDate, setLastFetchDate] = useState("");
  const role = "superAdmin";
  const navigate = useNavigate();

  const user = JSON.parse(sessionStorage.getItem("user"));

  // console.log(user?.permissions);

  // console.log(adminPermissions);

  const { value } = useContext(UserContext);
  const adminRole = sessionStorage.getItem("admin-role");
  const isClientAdmin = compareSync("client-admin", adminRole);
  const isSiteAdmin = compareSync("site-admin", adminRole);
  const isFacilitator = compareSync("facilitator", adminRole);

  const location = useLocation();
  const pathname = location.pathname;

  const onLoadFetch = useCallback(() => {
    setDataFetchTime();
  }, [role]);

  useEffect(() => {
    if (width < 768) {
      return setIsOpen(false);
    }
    setIsOpen(true);
  }, [width]);

  const setDataFetchTime = () => {
    // set time that data was fetched
    const currentTime = new Date().toLocaleString("en-GB", {
      hourCycle: "h12",
      month: "long",
      day: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "numeric",
    });
    setLastFetchDate(currentTime);
  };

  const [currentPageID, setCurrentPageID] = useState(0);

  // console.log(value);

  const setSelectedPageID = (id) => {
    setCurrentPageID(id);
    // persist selected page in session storage
    // sessionStorage.setItem('admPage', id);
  };

  const toggleSidebar = () => {
    setIsOpen((isOpen) => !isOpen);
  };

  return (
    <div style={{ backgroundColor: "#F2F3F7" }}>
      <AdminHeader
        siebarToggle={toggleSidebar}
        windowWidth={width}
        refreshData={onLoadFetch}
      />
      <div className="flex mt-3">
        {value?.user && (
          <Suspense
            fallback={
              <div
                className="bg-white w-full"
                style={{ height: "calc(100vh - 80px)" }}
              >
                <Fallback />
              </div>
            }
          >
            <AdminSidebar
              pages={
                isSiteAdmin ? ROUTES["site-admin"] : ROUTES["client-admin"]
              }
              setPage={setSelectedPageID}
              currentPage={currentPageID}
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              windowWidth={width}
              refreshTime={lastFetchDate}
            />
            <div
              style={{
                flex: "4",
                height: "calc(100vh - 60px)",
                backgroundColor: "#F2F3F7",
              }}
              className={`pr-2 pl-4 py-1 overflow-hidden ${
                width < 768 ? "w-full" : ""
              }`}
            >
              <div
                className="mt-0 w-full h-full overflow-y-auto"
                style={{ height: "inherit" }}
              >
                <AdminRoutes>
                  <Route
                    path="/"
                    element={
                      isSiteAdmin ? (
                        <Navigate to={"/admin/clients"} />
                      ) : (
                        <Navigate to={"/admin/dashboard"} />
                      )
                    }
                  />
                  <Route
                    path="*"
                    element={
                      <NotFoundPage
                        url={
                          isSiteAdmin ? "/admin/clients" : "/admin/dashboard"
                        }
                      />
                    }
                  />
                  <Route path="/dashboard/*" element={<DashboardRoutes />} />
                  <Route
                    path="/campaigns/*"
                    element={ <CampaignRoutes /> }
                  />
                  <Route
                    path={`/${URL.Exams}/*`}
                    element={ <ExamRoutes /> }
                  />
                  <Route
                    path={`/${URL.Exams}-new/*`}
                    element={ <ExamRoutesNew /> }
                  />
                  <Route path="/analytics/*" element={<AnalyticsRoutes />} />
                  <Route
                    path={
                      isClientAdmin ? "/billings/*" : "/site-admin-billings/*"
                    }
                    element={
                      isClientAdmin ? <BillingRoutes /> : <SiteAdminBillings />
                    }
                  />
                  <Route
                    path="/clients/*"
                    element={
                      !isClientAdmin ? (
                        <Clients width={width} designation={"site_admin"} />
                      ) : (
                        <Navigate to={"/"} />
                      )
                    }
                  />
                  <Route path="/report/*" element={<ReportRoutes />} />
                  <Route
                    path={`/${URL.view_affiliates}*`}
                    element={
                      !isClientAdmin ? (
                        <AffiliateRoutes />
                      ) : (
                        <Navigate to={"/"} />
                      )
                    }
                  />
                  <Route
                    path="/newsletter/*"
                    element={
                      !isClientAdmin ? <NewsLetter /> : <Navigate to={"/"} />
                    }
                  />
                  <Route
                    path="/candidates/*"
                    element={
                      <ProtectedRoute
                        permission={adminPermissions["view-all-candidates"]}
                      >
                        <CandidatesRoutes />
                      </ProtectedRoute>
                    }
                  />
                  <Route path="/downloads/*" element={<Downloads />} />
                  <Route path="/settings/*" element={<SettingsRoutes />} />
                  <Route path="/feedback/*" element={<FeedBackRoutes />} />
                  <Route path="/manage-users" element={<ManageUsers />} />
                  {/* Nested route for question bank */}
                  <Route
                    path={`/${URL.QuestionBank}/*`}
                    element={<QuestionBankRoutes />}
                  />
                  {/* 404 page */}
                  <Route
                    path="*"
                    element={
                      <div className="flex h-full w-full justify-center items-center">
                        <h5 className="text-xl"> 404 page not found. </h5>
                      </div>
                    }
                  />
                </AdminRoutes>
              </div>
            </div>
          </Suspense>
        )}
      </div>
    </div>
  );
};

export default AdminDashboard;
