import createApiClient from "./api";

// Create an Axios instance
const client = createApiClient();

export const api = {
    /******************************************************************************************** */
      // affiliate dashboard related endpoints
      getAffiliateCampaigns: ({ perPage, currentPage }) =>
       client
      .get(`v2/admin/campaigns?dropdown=0&affiliate=1&per_page=${perPage}&page=${currentPage}`)
      .then(({ data }) => data),

      getDesignations: () => client .get(`v2/admin/designations`).then(({ data }) => data),

      getStates: () => client .get(`/states`).then(({ data }) => data ),

      getAffiliates: ({ perPage, currentPage }) =>
       client
      .get(`v2/admin/affiliates?dropdown=0&per_page=${perPage}&page=${currentPage}`)
      .then(({ data }) => data),

      getAffiliateLinks: (payload) =>
       client
      .get(`v2/admin/affiliates/${payload.affiliateId}/links/${payload.campaignId}?per_page=1000`)
      .then(({ data }) => data), 
      
      createAffiliateLink: (payload) =>
       client
      .post(`v2/admin/affiliates/${payload.affiliateId}/links/${payload.campaignId}`)
      .then(({ data }) => data), 

      createAffiliate: (payload) => client.post(`v2/admin/affiliates`, payload ).then(({ data }) => data), 
     
      toggleAffiliate: (payload) => client.put(`v2/admin/affiliates/${payload}/toggle-status`)
      .then(({ data }) => data),
}  