import React from "react";
import Loader from "../Loader/Loader";
import Error from "../Error/Error";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeftLong } from "@fortawesome/free-solid-svg-icons";

function PageLayout({
  children,
  summary,
  title,
  isLoading,
  isError,
  errorText,
  backBtn,
  backRoute,
  className,
  mt,
  backBtnClassname,   
}) {
  if (isLoading) {
    return <Loader />;
  }

  if (isError) {
    return <Error err={errorText || null} />;
  }

  const navigate = useNavigate();

  const handleNavigate = () => {
    if (backRoute) {
      return navigate(backRoute);
    }

    navigate(-1);
  };

  return (
    <React.Fragment>
      {backBtn && (
        <div
          className={
            backBtnClassname
              ? backBtnClassname
              : "flex items-center justify-start mb-3 w-full bg-white rounded p-7"
          }
        >
          <button onClick={handleNavigate}>
            <FontAwesomeIcon icon={faArrowLeftLong} />
          </button>
        </div>
      )}
      {summary && (
        <div className="w-full bg-white rounded mb-3 px-7 py-3">
          <h1 className="text-customBlue text-baseFont text-lg">{title}</h1>
          {summary}
        </div>
      )}
      <div
        className={`${ className ? className : `w-full bg-white rounded p-7 mb-3 ${mt}`}`}
        id="page-layout"
      >
        {children}
      </div>
    </React.Fragment>
  );
}

export default PageLayout;
